<template>
  <b-container>
    <b-row class="vh-100" align-h="center">
      <b-col align-self="center" cols="12" sm="9" lg="5">
        <b-card>
          <h2 class="mb-4"></h2>
          <h2 class="mb-4">{{ $t('redirect.headline') }}</h2>
          <p>{{ $t('redirect.paragraph1') }}</p>
          <p v-html="$t('redirect.paragraph2')"></p>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'RedirectToV1',
};
</script>

<style scoped>

</style>
