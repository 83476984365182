var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        { staticClass: "vh-100", attrs: { "align-h": "center" } },
        [
          _c(
            "b-col",
            { attrs: { "align-self": "center", cols: "12", sm: "9", lg: "5" } },
            [
              _c("b-card", [
                _c("h2", { staticClass: "mb-4" }),
                _c("h2", { staticClass: "mb-4" }, [
                  _vm._v(_vm._s(_vm.$t("redirect.headline")))
                ]),
                _c("p", [_vm._v(_vm._s(_vm.$t("redirect.paragraph1")))]),
                _c("p", {
                  domProps: { innerHTML: _vm._s(_vm.$t("redirect.paragraph2")) }
                })
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }